import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
//import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight";
import Footer from "components/footers/SimpleFiveColumn.js";
import heroScreenshotImageSrc from "images/feature1.jpg";
import macHeroScreenshotImageSrc from "images/artificialintelligence.jpg";
import prototypeIllustrationImageSrc from "images/work.jpg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Services.</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Digital Transformation</Subheading>}
        heading={
        	<>
        	Make <HighlightedText>Digital Workers</HighlightedText> work for you.
        	</>
        }
        description={"Take a next step towards your digital journey and create an army of digital workers. These software bots will perform daily business processes and mundane tasks keeping employees free for jobs involving critical thinking and creativity. Resulting in reducing Manhours and saving considerably on employee salaries."}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        primaryButtonUrl={"/blog"}
      />
      <FeatureWithSteps
        subheading={<Subheading>Not sure about our services?</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={prototypeIllustrationImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>Quality Work</Subheading>}
        heading={
          <>
            Delivering technology <HighlightedText>Efficiently.</HighlightedText>
          </>
        }
        description={"Our solutions are delivered using technologies like RPA, AI, ML, IOT and in association with business experts.We deliver our solutions following the best methodologies and making sure our customer requirements are met. Our Customized Solutions are delivered in four phases following Agile methodology for  faster deployment so that the clients can get ROI as soon as possible and deliver a robust solution."}
        imageSrc={macHeroScreenshotImageSrc}
        showDecoratorBlob={true}
        primaryButtonUrl={"/how"}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Agile",
            description: "We assure that we deliever our projects faster ensuring customer requirements.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      <GetStarted/>
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Subscription Based Plan",
            price: "",
            duration: "",
            mainFeature: "For Enterprises of all sizes",
            features: ["Monthly Plans", "Yearly Plans", "Simple Licensing Plans", "Basic Assistance"]
          },
          {
            name: "Support Included",
            price: "",
            duration: "",
            mainFeature: "Manage and Mantain after deployment",
            features: ["Avoid Bot Failures", "Process Documentation", "Change in process flow", "Priority Assistance available"],
            featured: true
          },
          {
            name: "No Hidden Charges",
            price: "",
            duration: "",
            mainFeature: "Simple Plan Structure",
            features: ["Monthly billing", "Bot and Support charged together", "Transperancy", "Pre Billing Support"]
          }
        ]}
      />
     
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What is RPA?",
            answer:
              "RPA that is Robot Process Automation involves automation using software bots. RPA Robots can integrate with multiple existing applications to complete business processes. They can interact with the user interface to capture information and navigate and interact with systems in the same manner that a human user would. They can be designed to interpret information, send responses and communicate with other systems to undertake repetitive, or time-consuming tasks, some of which were not previously achievable with humans. The benefits of using robots are numerous; they don’t sleep, make no errors, and require fewer overheads or management. Managed via a central Control Centre you can have complete visibility of all the work your new “digital workforce” is doing, and orchestrate their work."
          },
          {
            question: "How long does it take to implement RPA ?",
            answer:
              "An RPA project can be completed from months to weeks. And clients can start taking advantage of automation as quickly as possible. Due to faster deployment and  implementation clients can expect a faster ROI."
          },
          {
            question: "Data Security Concerns?",
            answer:
              "All the data is processed locally on clients system ensuring client data security. Clients concerned about security can opt for full On-Premises deployment or full On-Cloud Deployment according to their needs."
          },
          {
            question: "What is Hyperautomation ?",
            answer:
              "Hyperautomation, a term recently coined by Gartner, refers to the combination of a number of new technologies, and a clear strategy as to how they can help your business enter a new age of automation.  This approach is core to our business, and allows us to provide the right solutions in the correct manner to our customers. Starting with RPA as a core module integrating each of these technologies such as Process Discovery, OCR and Intelligent Data Capture, BPM, Chatbots, Machine Learning, Cognitive Services and Analytics . The combination of these creates end-to-end automation and unleashes previously unreachable potential for your organisation. "
          },
          {
            question: "Solution is suitable for which Industry ?",
            answer:
              "The Solution is suitable for any industry ranging from Finance, Manufacturing, Healthcare, IT, Communications etc."
          }
        ]}
      />
      
      <Footer />
    </AnimationRevealPage>
  );
}
