import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import Team from "components/cards/ProfileThreeColGrid1.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "https://cdn.pixabay.com/photo/2015/01/08/18/27/startup-593341_960_720.jpg",
      subtitle: "Focus",
      title: "We focus on Intelligent solutions.",
      description:
        "Our people are flexible, intelligent and considerate of others personalities and cultures. Whilst we have a great deal of industry experience, and some pre-defined ideas as to how things should be done, we are always open to new ideas, challenges and opinions.   At Asentir, there is no normal, there is no set-way of doing things, we always seek to improve and we are never too proud to admit when we are wrong, or when something can be done better. When we work with you we will not dictate, rather collaborate with your teams to ensure the maximum possible results are achieved.",
      url: "/contactus"
    },

    {
      imageSrc:
        "https://cdn.pixabay.com/photo/2015/01/08/18/11/laptops-593296_960_720.jpg",
      subtitle: "Vision",
      title: "We aim to disrupt the Automation space.",
      description:
        "Our vision is to provide affordable and robust automation solutions with minimum implementation and integration time. Provide solutions considering data security and future scalability of the automation journey of our customer. We believe in providing services with same enthusiasm and suppport through out our business relations with the customer and keen towards developing better relations with them to fascilitate better communication apparently better service delivery..We engage customers as they begin their automation journey, and provide the support and guidance they need to succeed.  We help businesses radically improve how they work, so they can increase their value and offer customers and staff better experience.",
      url: "/contactus"
    }
    
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Who we are</HeadingTitle>
          <HeadingDescription>
            We’re building a great bunch of people. At Asentir we’re dedicated to expertise, skill, inclusivity and last but certainly not least – fun.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Team/>  
        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>Learn More</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
