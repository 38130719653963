import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
import AboutUsPage from "pages/AboutUs.js";
//import PricingPage from "pages/Pricing.js";
import ContactUsPage from "pages/ContactUs.js";
import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
import SolutionsPage from "pages/Solutions.js";
import SolutionsContent from "pages/SolutionsContent.js";
import BlogContent from "pages/BlogContent.js";
import HowPage from "pages/How.js";
export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <Router>
      <Switch>

        <Route path="/aboutus">
          <AboutUsPage/>
        </Route>
        <Route path="/blog">
          <BlogIndexPage/>
        </Route>
        <Route path="/contactus">
          <ContactUsPage/>
        </Route>
        <Route path="/solutions">
        <SolutionsPage/>
        </Route>
        <Route path="/how">
        <HowPage/>
        </Route>  
        <Route exact path="/solutionscontent/:id" render={props => <SolutionsContent {...props} />} />
        <Route exact path="/blogcontent/:id" render={props => <BlogContent {...props} />} />                
        <Route path="/">
           <SaaSProductLandingPage/>
        </Route>   

      </Switch>
    </Router>
  );
}


