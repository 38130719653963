import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import postlist from "data/blog/blog.json";
import { Redirect } from "react-router-dom";
import { SectionDescription } from "components/misc/Typography.js";


const Heading = tw(SectionHeading)`w-full`;
const Subheading = tw.div`w-full text-center text-secondary-100`;
const VerticalSpacer = tw.div`mt-10 w-full`;
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-10 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = tw(Column)`md:mr-12 lg:mr-16 md:order-first`;
const HeadingOfSolution = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-2xl text-gray-900 leading-tight`;
const HeadingSmall = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-base text-gray-900 leading-tight`;



const Post = (props) => {
    const validId = parseInt(props.match.params.id)
    if (!validId) {
        return <Redirect to="/404" />
    }
    const fetchedPost = {}
    let postExists = false
    postlist.forEach((post, i) => {
        if (validId === post.id) {
            fetchedPost.category = post.category ? post.category : ""
            fetchedPost.title = post.title ? post.title : "" 
            fetchedPost.date = post.date ? post.date : ""
            fetchedPost.author = post.author ? post.author : ""
            fetchedPost.description = post.description ? post.description : ""
            fetchedPost.imageSrc1 = post.imageSrc1 ? post.imageSrc1 : ""
            fetchedPost.subheading1 = post.subheading1 ? post.subheading1 : false
            fetchedPost.content1 = post.content1 ? post.content1 : false
            fetchedPost.subheading2 = post.subheading2 ? post.subheading2 : false
            fetchedPost.content2 = post.content2 ? post.content2 : false

            fetchedPost.subheading3 = post.subheading3 ? post.subheading3 : false
            fetchedPost.content3 = post.content3 ? post.content3 : false
            fetchedPost.subheading4 = post.subheading4 ? post.subheading4 : false
            fetchedPost.content4 = post.content4 ? post.content4 : false

            fetchedPost.subheading5 = post.subheading5 ? post.subheading5 : false
            fetchedPost.content5 = post.content5 ? post.content5 : false
            fetchedPost.subheading6 = post.subheading6 ? post.subheading6 : false
            fetchedPost.content6 = post.content6 ? post.content6 : false

            fetchedPost.subheading7 = post.subheading7 ? post.subheading7 : false
            fetchedPost.content7 = post.content7 ? post.content7 : false
            fetchedPost.subheading8 = post.subheading8 ? post.subheading8 : false
            fetchedPost.content8 = post.content8 ? post.content8 : false

            fetchedPost.subheading9 = post.subheading9 ? post.subheading9 : false
            fetchedPost.content9 = post.content9 ? post.content9 : false
            fetchedPost.subheading10 = post.subheading10 ? post.subheading10 : false
            fetchedPost.content10 = post.content10 ? post.content10 : false
            
            postExists = true
        }
    })
    if (postExists === false) {
        return <Redirect to="/404" />
    }
    return (
                  

    <AnimationRevealPage>
      <Header />
      <Container>
      <VerticalSpacer/>
      <Subheading>{fetchedPost.category}</Subheading>
      <Heading>{fetchedPost.title}</Heading>
      <Subheading>Published by : {fetchedPost.author} on {fetchedPost.date}</Subheading>
      <VerticalSpacer/>
       <TwoColumn>
            <TextColumn>
                <HeadingOfSolution>Description</HeadingOfSolution>
                <VerticalSpacer/>
                <h2>{fetchedPost.description}</h2>
                <VerticalSpacer/>
            </TextColumn>
            <ImageColumn> 
                <VerticalSpacer/>
                 <img src={fetchedPost.imageSrc1}/>
                <VerticalSpacer/>
            </ImageColumn>    
       </TwoColumn>
       {fetchedPost.content1 ?(
       <TwoColumn>
            <TextColumn>
                {fetchedPost.subheading1 ? 
                    (<HeadingOfSolution>{fetchedPost.subheading1}</HeadingOfSolution>):(<VerticalSpacer/>)}
                <VerticalSpacer/>
                {fetchedPost.content1}
                <VerticalSpacer/>
            </TextColumn>
            <ImageColumn> 
                 {fetchedPost.subheading2 ? 
                    (<HeadingOfSolution>{fetchedPost.subheading2}</HeadingOfSolution>):(<VerticalSpacer/>)}
                <VerticalSpacer/>
                {fetchedPost.content2}
                <VerticalSpacer/>
            </ImageColumn>    
       </TwoColumn>):(<br/>)
        }

        {fetchedPost.content3 ?(
       <TwoColumn>
            <TextColumn>
                {fetchedPost.subheading3 ? 
                    (<HeadingOfSolution>{fetchedPost.subheading3}</HeadingOfSolution>):(<VerticalSpacer/>)}
                <VerticalSpacer/>
                {fetchedPost.content3}
                <VerticalSpacer/>
            </TextColumn>
            <ImageColumn> 
                 {fetchedPost.subheading4 ? 
                    (<HeadingOfSolution>{fetchedPost.subheading4}</HeadingOfSolution>):(<VerticalSpacer/>)}
                <VerticalSpacer/>
                {fetchedPost.content4}
                <VerticalSpacer/>
            </ImageColumn>    
       </TwoColumn>):(<br/>)
        }

        {fetchedPost.content5 ?(
       <TwoColumn>
            <TextColumn>
                {fetchedPost.subheading5 ? 
                    (<HeadingOfSolution>{fetchedPost.subheading5}</HeadingOfSolution>):(<VerticalSpacer/>)}
                <VerticalSpacer/>
                {fetchedPost.content5}
                <VerticalSpacer/>
            </TextColumn>
            <ImageColumn> 
                 {fetchedPost.subheading6 ? 
                    (<HeadingOfSolution>{fetchedPost.subheading6}</HeadingOfSolution>):(<VerticalSpacer/>)}
                <VerticalSpacer/>
                {fetchedPost.content6}
                <VerticalSpacer/>
            </ImageColumn>    
       </TwoColumn>):(<br/>)
        }

        {fetchedPost.content7 ?(
       <TwoColumn>
            <TextColumn>
                {fetchedPost.subheading7 ? 
                    (<HeadingOfSolution>{fetchedPost.subheading7}</HeadingOfSolution>):(<VerticalSpacer/>)}
                <VerticalSpacer/>
                {fetchedPost.content7}
                <VerticalSpacer/>
            </TextColumn>
            <ImageColumn> 
                 {fetchedPost.subheading8 ? 
                    (<HeadingOfSolution>{fetchedPost.subheading8}</HeadingOfSolution>):(<VerticalSpacer/>)}
                <VerticalSpacer/>
                {fetchedPost.content8}
                <VerticalSpacer/>
            </ImageColumn>    
       </TwoColumn>):(<br/>)
        }

        {fetchedPost.content9 ?(
       <TwoColumn>
            <TextColumn>
                {fetchedPost.subheading9 ? 
                    (<HeadingOfSolution>{fetchedPost.subheading9}</HeadingOfSolution>):(<VerticalSpacer/>)}
                <VerticalSpacer/>
                {fetchedPost.content9}
                <VerticalSpacer/>
            </TextColumn>
            <ImageColumn> 
                 {fetchedPost.subheading10 ? 
                    (<HeadingOfSolution>{fetchedPost.subheading10}</HeadingOfSolution>):(<VerticalSpacer/>)}
                <VerticalSpacer/>
                {fetchedPost.content10}
                <VerticalSpacer/>
            </ImageColumn>    
       </TwoColumn>):(<br/>)
        }


      </Container>     
      <Footer />
    </AnimationRevealPage>
        
    )
}

export default Post