import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import postlist from "data/solutions/solutions.json";
import { Redirect } from "react-router-dom";
import { SectionDescription } from "components/misc/Typography.js";


const Heading = tw(SectionHeading)`w-full`;
const Subheading = tw.div`w-full text-center text-secondary-100`;
const VerticalSpacer = tw.div`mt-10 w-full`;
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-10 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = tw(Column)`md:mr-12 lg:mr-16 md:order-first`;
const HeadingOfSolution = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-2xl text-gray-900 leading-tight`;



const Post = (props) => {
    const validId = parseInt(props.match.params.id)
    if (!validId) {
        return <Redirect to="/404" />
    }
    const fetchedPost = {}
    let postExists = false
    postlist.forEach((post, i) => {
        if (validId === post.id) {
            fetchedPost.title = post.title ? post.title : ""
            fetchedPost.category = post.category ? post.category : ""
            fetchedPost.summary = post.summary ? post.summary : ""
            fetchedPost.overview = post.overview ? post.overview : ""
            fetchedPost.benefits = post.benefits ? post.benefits : ""
            fetchedPost.imageSrc1 = post.imageSrc1 ? post.imageSrc1 :false
            fetchedPost.videoSrc = post.videoSrc ? post.videoSrc : false
            postExists = true
        }
    })
    if (postExists === false) {
        return <Redirect to="/404" />
    }
    return (
                  

    <AnimationRevealPage>
      <Header />
      <Container>
      <VerticalSpacer/>
      <Heading>{fetchedPost.title}</Heading>
      <Subheading>{fetchedPost.category}</Subheading>
     
       <TwoColumn>
            <TextColumn>
                <HeadingOfSolution>Summary</HeadingOfSolution>
                <VerticalSpacer/>

                <h2>{fetchedPost.summary}</h2>
                <VerticalSpacer/>
                {fetchedPost.videoSrc?
                    (<iframe width="560" height="315" src={fetchedPost.videoSrc} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>)
                :(<br/>)}
                
            </TextColumn>
            <ImageColumn> 
                <VerticalSpacer/>
                
                {fetchedPost.imageSrc1?
                    (<img src={fetchedPost.imageSrc1}/>)
                :(<br/>)}
                
            </ImageColumn>    
       </TwoColumn>
       <TwoColumn>
            <TextColumn>
                <HeadingOfSolution>Overview</HeadingOfSolution>
                <VerticalSpacer/>
                <td dangerouslySetInnerHTML={{__html: fetchedPost.overview}} />
                <VerticalSpacer/>
            </TextColumn>
            <ImageColumn> 
                <HeadingOfSolution>Benefits</HeadingOfSolution>
                <VerticalSpacer/>
                <td dangerouslySetInnerHTML={{__html: fetchedPost.benefits}} />
            <VerticalSpacer/>
            </ImageColumn>    
       </TwoColumn> 

      </Container>     
      <Footer />
    </AnimationRevealPage>
        
    )
}

export default Post