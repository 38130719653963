import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import Features from "components/features/ThreeColSimple.js";
import AlternateImage from "components/features/VerticalWithAlternateImageAndText.js"
import SupportIconImage from "images/discipline.svg";
import ShieldIconImage from "images/perseverance.svg";
import CustomerLoveIconImage from "images/heart.svg";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <AlternateImage/>
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Enjoying what you do is just as important as being good at it. We believe work is much more than a desk in an office, it's a true community of excellent people. Our people love coming to work because they learn, grow and achieve whilst they are together keeping these core values."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Discipline",
            description: "We make fun as part of our work following discipline to make sure the work gets done."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Perseverance",
            description: "Making Sure we keep working in achieving our clients as well as our goals. "
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Integrity",
            description: "Engage in transaction that will benifit our clients and partners."
          },
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
